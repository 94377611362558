import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { CRMDetails } from "../../../../../../types/clients.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function updateClientCRMDetails(
  clientId: string,
  crmDetails: CRMDetails,
): Promise<void> {
  await axiosInstance.put(`/clients/${clientId}/crm_details`, crmDetails, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useUpdateClientCRMDetails(
  clientId: string,
): UseMutateAsyncFunction<void, unknown, CRMDetails, unknown> {
  const { mutateAsync } = useMutation((crmDetails: CRMDetails) =>
    updateClientCRMDetails(clientId, crmDetails),
  );

  return mutateAsync;
}
