import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteClientPayments(
  clientId: string,
  billsIds: string[],
): Promise<void> {
  await axiosInstance.delete(
    `/clients/${clientId}/payments/?ids=${billsIds.join(",")}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useDeleteClientPayments(
  clientId: string,
): UseMutateFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((paymentsIds: string[]) =>
    deleteClientPayments(clientId, paymentsIds),
  );

  return mutateAsync;
}
