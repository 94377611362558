import {
  BlockStack,
  Box,
  Button,
  Card,
  IndexTable,
  InlineStack,
  Link,
  Text,
} from "@shopify/polaris";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store, StoreAgent } from "../../../../../types/common.types";
import { useClientStores } from "./hooks/useClientStores";
import ClientStoreModal from "./ClientStoreModal";

export function StoresTable({ clientId }: { clientId: string }) {
  const navigate = useNavigate();

  const [clientStoreModalActive, setClientStoreModalActive] = useState(false);
  const [modalStore, setModalStore] = useState<Store | null>(null);

  const { stores, isFetching, page, maxPage, minPage, setPage } =
    useClientStores(clientId);

  const rowMarkup = stores.map((store: Store, index) => (
    <IndexTable.Row
      id={store.id as string}
      key={store.id}
      position={index}
      onClick={() => {
        setModalStore(store);
        setClientStoreModalActive(true);
      }}
    >
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link dataPrimaryLink>
          <Text fontWeight="bold" as="span">
            {store.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <BlockStack>
          {store.agents.map((agent: StoreAgent) => (
            <Button
              key={agent.id}
              variant="plain"
              onClick={() => navigate(`/admin/agents/${agent.id}`)}
            >
              {agent.name}
            </Button>
          ))}
        </BlockStack>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const handleClientStoreActive = (val: boolean) => {
    setClientStoreModalActive(val);

    if (!val) {
      setModalStore(null);
    }
  };

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              Stores
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                setModalStore(null);
                setClientStoreModalActive(true);
              }}
            >
              Add
            </Button>
          </InlineStack>
        </Box>
        <IndexTable
          resourceName={{
            singular: "Store",
            plural: "Stores",
          }}
          itemCount={stores.length}
          headings={[{ title: "Name" }, { title: "Agents" }]}
          loading={isFetching}
          selectable={false}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
        <ClientStoreModal
          store={modalStore}
          clientId={clientId as string}
          active={clientStoreModalActive}
          setActive={handleClientStoreActive}
        />
      </BlockStack>
    </Card>
  );
}
