import React, { useContext, useState } from "react";
import {
  Page,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Badge,
  LegacyCard,
  BlockStack,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import ClientContactInformation from "./components/ContactInformation/ClientContactInformation";
import { useClient } from "./hooks/useClient";
import { StoresTable } from "./components/StoresTables/StoresTable";
import { PaymentsTable } from "./components/PaymentsTable/PaymentsTable";
import { useUpdateClient } from "./hooks/useUpdateClient";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { UsagePaymentsTable } from "./components/UsagePaymentsTable/UsagePaymentsTable";
import CRMDetails from "./components/CRMDetails/CRMDetails";
import { Client } from "../../../types/clients.types";
import InternalDetails from "./components/InternalDetails/InternalDetails";

export default function ClientPage() {
  const navigate = useNavigate();
  const { clientId } = useParams();

  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);

  const [loading, setLoading] = useState(false);

  const { client, isLoading, isError } = useClient(clientId as string);

  const updateClient = useUpdateClient();

  if (isError) {
    navigate("/admin/clients");
  }

  const onStatusChange = async () => {
    if (!client) return;

    setLoading(true);

    try {
      await updateClient({
        ...client,
        active: !client.active,
      });

      onShowToast("Client Status Changed", false);

      await queryClient.invalidateQueries([
        queryKeysConstants.client,
        clientId,
      ]);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setLoading(false);
  };

  if (isLoading)
    return (
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <LegacyCard sectioned>
              <SkeletonBodyText />
            </LegacyCard>
            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      backAction={{
        url: "/admin/clients",
      }}
      titleMetadata={
        <Badge tone={client?.active ? "success" : "critical"}>
          {client?.active ? "Active" : "Not Active"}
        </Badge>
      }
      title={client?.name}
      primaryAction={{
        content: client?.active ? "Deactivate" : "Activate",
        destructive: client?.active,
        onAction: onStatusChange,
        loading,
        disabled: loading,
      }}
    >
      <Layout>
        <Layout.Section variant="oneHalf">
          <BlockStack gap="200">
            <ClientContactInformation client={client as Client} />
            <StoresTable clientId={clientId as string} />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <BlockStack gap="200">
            <InternalDetails client={client as Client} />
            <CRMDetails client={client as Client} />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <UsagePaymentsTable client={client as Client} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <PaymentsTable clientId={clientId as string} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
