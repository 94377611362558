import {
  Modal,
  FormLayout,
  Autocomplete,
  Tag,
  InlineStack,
} from "@shopify/polaris";
import React, { useCallback, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import TextFieldInput from "../../../../../components/TextFieldInput";
import { Store } from "../../../../../types/common.types";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import SelectInput from "../../../../../components/SelectInput";
import { useAddUpdateClientStore } from "./hooks/useAddUpdateClientStore";
import { Agent } from "../../../../../types/agents.types";

interface ClientStoreModalProps {
  store?: Store | null;
  clientId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    id: yup.string().nullable(),
    client_id: yup.string().nullable(),
    name: yup.string().required(),
    url: yup.string().required(),
    monthly_rate: yup.number().min(0).required(),
    support_email: yup.string().nullable(),
    support_phone_number: yup.string().nullable(),
    live_chat_support: yup.boolean().required(),
    agents_ids: yup.array().nullable(),
  })
  .required();

export default function ClientStoreModal({
  store,
  clientId,
  active,
  setActive,
}: ClientStoreModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, watch, getValues, setValue, reset } =
    useForm<Store>({
      resolver: yupResolver(schema),
    });
  const watchAgentsIds = watch("agents_ids");

  const addUpdateClientStore = useAddUpdateClientStore(clientId, !!store);
  const { agents, isFetching } = { isFetching: false, agents: [] };

  const deselectedOptions = agents.map(
    (agent: Agent) =>
      ({
        label: `${agent.first_name} ${agent.last_name}`,
        value: agent.id,
      }) as OptionDescriptor,
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(deselectedOptions);

  const handleClose = () => {
    reset();
    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (store: Store) => {
    setLoading(true);

    try {
      await addUpdateClientStore(store);

      await queryClient.invalidateQueries([
        queryKeysConstants.stores,
        clientId,
      ]);

      handleClose();

      onShowToast(`Store ${store ? "updated" : "added"}`, false);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setLoading(false);
  };

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter(
        (option) => option.label?.toString().match(filterRegex),
      );
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: string[]) => {
      const previousAgents: string[] = getValues("agents_ids");

      setSelectedOptions(selected);
      setInputValue("");

      if (!previousAgents.includes(selected[0]))
        setValue("agents_ids", [...previousAgents, selected[0]]);
    },
    [options],
  );

  const getAgentNameById = (agentId: string): string | undefined => {
    const matchedOption: Agent | undefined = agents.find((agent: Agent) => {
      return agent.id?.match(agentId);
    });

    return (
      matchedOption && `${matchedOption.first_name} ${matchedOption.last_name}`
    );
  };

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label="Agents"
      value={inputValue}
      placeholder="Search"
      autoComplete="off"
    />
  );

  const removeAgent = useCallback(
    (agentId: string) => () => {
      const previousAgents: string[] = getValues("agents_ids");

      setValue(
        "agents_ids",
        previousAgents.filter((agent) => agent !== agentId),
      );
    },
    [],
  );

  useEffect(() => {
    reset({
      id: store?.id || null,
      client_id: clientId,
      name: store?.name || "",
      url: store?.url || "",
      support_email: store?.support_email || "",
      support_phone_number: store?.support_phone_number || "",
      live_chat_support: store?.live_chat_support || false,
      agents_ids: store?.agents_ids || [],
      agents: store?.agents || [],
    });
  }, [store]);

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={store?.name || "Add Store"}
      loading={isFetching}
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextFieldInput control={control} label="Store Name" name="name" />
            <TextFieldInput control={control} label="URL" name="url" />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextFieldInput
              control={control}
              label="Support Email"
              name="support_email"
            />
            <TextFieldInput
              control={control}
              label="Support Phone Number"
              name="support_phone_number"
            />
            <SelectInput
              control={control}
              label="Live Chat"
              name="live_chat_support"
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </FormLayout.Group>
          <Autocomplete
            options={options}
            selected={selectedOptions}
            onSelect={updateSelection}
            textField={textField}
          />
          <InlineStack>
            {watchAgentsIds?.map((agentId) => (
              <Tag key={agentId} onRemove={removeAgent(agentId)}>
                {getAgentNameById(agentId)}
              </Tag>
            ))}
          </InlineStack>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
