import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
  useIndexResourceState,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useContext, useState } from "react";
import { DeleteIcon } from "@shopify/polaris-icons";
import { useAgentPayments } from "./hooks/useAgentPayments";
import { formatDate, formatMoney } from "../../../../../helpers/helpers";
import AddAgentPaymentModal from "./AddAgentPaymentModal";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useDeleteAgentPayments } from "./hooks/useDeleteAgentPayments";
import { AgentPayment } from "../../../../../types/agents.types";

export default function AgentPayments({ agentId }: { agentId: string }) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const columns = [{ label: "Date" }, { label: "Amount" }];

  const [modalActive, setModalActive] = useState(false);
  const { payments, isFetching } = useAgentPayments(agentId, 10);
  const deleteAgentPayments = useDeleteAgentPayments(agentId);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(
    payments.map((payment: AgentPayment) => ({ ...payment })),
  );

  const handleDelete = async () => {
    onShowToast("Deleting Payments", false);

    try {
      await deleteAgentPayments(selectedResources);
      await queryClient.invalidateQueries([
        queryKeysConstants.agents,
        agentId,
        "payments",
      ]);

      clearSelection();
    } catch (e: any) {
      onShowToast("Something went wrong", true);
    }
  };

  const rowMarkup = payments.map((payment: AgentPayment, index) => (
    <IndexTable.Row
      id={payment.id as string}
      key={payment.id}
      position={index}
      selected={selectedResources.includes(payment.id as string)}
    >
      <IndexTable.Cell>{formatMoney(payment.amount)}</IndexTable.Cell>
      <IndexTable.Cell>{formatDate(payment.date, true, true)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              Payments
            </Text>
            <Button variant="plain" onClick={() => setModalActive(true)}>
              Add
            </Button>
          </InlineStack>
        </Box>

        <IndexTable
          resourceName={{
            singular: "Payment",
            plural: "Payments",
          }}
          itemCount={payments.length}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          loading={isFetching}
          selectable
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          bulkActions={[
            {
              icon: DeleteIcon,
              destructive: true,
              content: "Delete payments",
              onAction: handleDelete,
            },
          ]}
        >
          {rowMarkup}
        </IndexTable>
      </BlockStack>
      <AddAgentPaymentModal
        agentId={agentId}
        active={modalActive}
        setActive={setModalActive}
      />
    </Card>
  );
}
