export enum PaymentSchedule {
  BI_WEEKLY = "bi_weekly",
  MONTHLY = "monthly",
}

export interface Address {
  street_address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export enum CRMType {
  ZENDESK = "ZENDESK",
  FRESHDESK = "FRESHDESK",
}

export interface CRMDetails {
  type: CRMType;
  subdomain: string;
  email: string;
  password: string;
}

export interface Client {
  id: string | null;
  name: string;
  email: string;
  company_name?: string;
  monthly_rate: number | 0;
  billing_address?: Address;
  total_stores: number;
  total_paid: number;
  bills_count: number;
  payment_schedule: PaymentSchedule | null;
  started_at: Date;
  last_billed_at: Date | null;
  next_billing_at: Date | null;
  zendesk_name: string;
  zendesk_email: string;
  zendesk_password: string;
  crm_details: CRMDetails | null;
  active: boolean | true;
  notes: string;
  stripe_customer_id?: string;
}
