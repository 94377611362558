import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";
import { Payment } from "../../../../../../types/payments.types";

async function addPayment(clientId: string, payment: Payment): Promise<void> {
  await axiosInstance.post(`/clients/${clientId}/payments/`, payment, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

async function updatePayment(
  clientId: string,
  payment: Payment,
): Promise<void> {
  await axiosInstance.put(
    `/clients/${clientId}/payments/${payment.id}`,
    payment,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useAddUpdatePayment(
  clientId: string,
  update: boolean,
): UseMutateFunction<void, unknown, Payment, unknown> {
  const { mutateAsync } = useMutation((payment: Payment) =>
    update ? updatePayment(clientId, payment) : addPayment(clientId, payment),
  );

  return mutateAsync;
}
