import { Frame, Navigation } from "@shopify/polaris";
import {
  PersonIcon,
  HomeIcon,
  NoteIcon,
  SettingsIcon,
} from "@shopify/polaris-icons";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useState } from "react";
import ClientsPage from "../pages/AdminDashboard/Clients/Clients";

import logo from "../config/logo";
import Header from "../components/Header";
import ClientPage from "../pages/AdminDashboard/Clients/Client";
import { ToastAlert } from "../components/ToastAlert";
import AgentsPage from "../pages/AdminDashboard/Agents/AgentsPage";
import AgentPage from "../pages/AdminDashboard/Agents/AgentPage";

export default function AdminDashboard() {
  const location = useLocation();
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const onMobileNavigationToggle = () => {
    setShowMobileNavigation(!showMobileNavigation);
  };

  const onHideMobileNavigation = () => {
    setShowMobileNavigation(false);
  };

  const navigationMarkup = (
    <Navigation onDismiss={onHideMobileNavigation} location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: "/admin/home",
            label: "Home",
            icon: HomeIcon,
            disabled: true,
          },
          {
            url: "/admin/clients",
            label: "Clients",
            icon: PersonIcon,
          },
          {
            url: "/admin/agents",
            label: "Agents",
            icon: PersonIcon,
          },
          {
            url: "/admin/reports",
            label: "Reports",
            icon: NoteIcon,
            disabled: true,
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: "/admin/settings",
            label: "Settings",
            icon: SettingsIcon,
            disabled: true,
          },
        ]}
      />
    </Navigation>
  );

  return (
    <Frame
      logo={logo}
      topBar={<Header onNavigationToggle={onMobileNavigationToggle} />}
      navigation={navigationMarkup}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={onHideMobileNavigation}
    >
      <Routes>
        <Route path="/clients" element={<ClientsPage />} />
        <Route path="/clients/:clientId" element={<ClientPage />} />

        <Route path="/agents" element={<AgentsPage />} />
        <Route path="/agents/:agentId" element={<AgentPage />} />

        <Route path="*" element={<Navigate to="/admin/clients" />} />
      </Routes>
      <ToastAlert />
    </Frame>
  );
}
