import { Navigate, useLocation } from "react-router-dom";
import React from "react";

type Props = {
  isLoggedIn: boolean;
  children: React.ReactElement;
  redirectTo: string;
};

type RoleGatedProps = {
  requiredRole: string;
  userRole: string;
  children: React.ReactElement;
};

export function Protected({ isLoggedIn, children, redirectTo }: Props) {
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to={`${redirectTo}?return_to=${location.pathname}`} />;
  }

  return children;
}

export function NotProtected({ isLoggedIn, children, redirectTo }: Props) {
  if (isLoggedIn) {
    return <Navigate to={redirectTo} />;
  }

  return children;
}

export function RoleGated({
  requiredRole,
  userRole,
  children,
}: RoleGatedProps) {
  if (requiredRole === userRole) {
    return children;
  }

  return null;
}
