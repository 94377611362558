import { FormLayout, Modal } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useAddAgentPayment } from "./hooks/useAddAgentPayment";
import { AgentPayment } from "../../../../../types/agents.types";
import TextFieldInput from "../../../../../components/TextFieldInput";
import DatePickerInput from "../../../../../components/DatePickerInput";

interface Props {
  agentId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    amount: yup.number().required(),
    date: yup.date().required(),
  })
  .required();

export default function AddMonthlyPaymentModal({
  agentId,
  active,
  setActive,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { control, reset, handleSubmit } = useForm<AgentPayment>({
    defaultValues: {
      amount: 0,
      date: new Date(),
    },
    resolver: yupResolver(schema),
  });
  const addAgentPayment = useAddAgentPayment(agentId);

  const handleClose = () => {
    reset({
      amount: 0,
      date: new Date(),
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (payment: AgentPayment) => {
    setLoading(true);

    try {
      await addAgentPayment(payment);

      await queryClient.invalidateQueries([
        queryKeysConstants.agents,
        agentId,
        "payments",
      ]);

      handleClose();

      onShowToast("Agent Payment Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      title="Add new payment"
      onClose={handleClose}
      primaryAction={{
        content: "Save",
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
      secondaryActions={[
        {
          content: "Cancel",
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput control={control} name="amount" label="Amount" />
          <DatePickerInput control={control} name="date" label="Date" />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
