import {
  Layout,
  Card,
  Page,
  FormLayout,
  Button,
  Text,
  BlockStack,
} from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { User } from "../../types/common.types";
import AlertContext, { AlertContextType } from "../../contexts/alert.context";
import { sendForgotPasswordEmail } from "../../services/auth.service";
import TextFieldInput from "../../components/TextFieldInput";

const schema = yup
  .object({
    email: yup.string().required(),
  })
  .required();

export default function ForgotPasswordPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<User>({
    resolver: yupResolver(schema),
  });

  const onHandleSubmit = async (user: User) => {
    setLoading(true);

    try {
      await sendForgotPasswordEmail(user.email);

      onShowToast(
        "Please follow the instructions on your email to reset your password.",
        false,
      );

      setTimeout(() => {
        navigate("/auth", {
          replace: true,
        });
      }, 3000);
    } catch (err) {
      console.error(err);
      onShowToast("Something went wrong.", true);
    }

    setLoading(false);
  };

  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap="500">
              <Text as="span" fontWeight="bold">
                {t("reset_password")}
              </Text>
              <FormLayout>
                <TextFieldInput
                  control={control}
                  name="email"
                  label={t("email")}
                  type="email"
                />
              </FormLayout>
              <Button
                variant="primary"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit(onHandleSubmit)}
                size="large"
              >
                Send Instructions
              </Button>
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
