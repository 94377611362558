import {
  BlockStack,
  Card,
  InlineGrid,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { Client } from "../../../../../types/clients.types";
import { GridField } from "../../../../../components/GridField";
import { formatDate } from "../../../../../helpers/helpers";

export default function InternalDetails({ client }: { client: Client }) {
  return (
    <Card>
      <BlockStack gap="200">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            Internal Details
          </Text>
        </InlineStack>
        <InlineGrid columns="1" gap="300">
          <GridField name="Stripe Customer ID">
            {client.stripe_customer_id}
          </GridField>
          <GridField name="Last billed at">
            {client.last_billed_at
              ? formatDate(client.last_billed_at, true, true)
              : "-"}
          </GridField>
          <GridField name="Next billing at">
            {client.next_billing_at
              ? formatDate(client.next_billing_at, true, true)
              : "-"}
          </GridField>
        </InlineGrid>
      </BlockStack>
    </Card>
  );
}
