import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import {
  Modal,
  FormLayout,
  Button,
  DataTable,
  InlineStack,
} from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldInput from "../../../../../components/TextFieldInput";
import { Usage, UsagePayment } from "../../../../../types/usagePayments.types";
import { formatMoney, sumArray } from "../../../../../helpers/helpers";
import DatePickerInput from "../../../../../components/DatePickerInput";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useAddUpdateUsagePayment } from "./hooks/useAddUpdateUsagePayment";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import { Client } from "../../../../../types/clients.types";

interface UsagePaymentModalProps {
  client: Client;
  usagePayment?: UsagePayment | null;
  active: boolean;
  setActive: (status: boolean) => void;
}

const usageSchema = yup.object({
  tickets: yup.number().min(0).required(),
  rate: yup.number().min(0.5).required(),
});
const usagePaymentDatesSchema = yup.object({
  due_date: yup.date().required(),
  date_paid: yup.date().required(),
});

export default function UsagePaymentModal({
  client,
  usagePayment,
  active,
  setActive,
}: UsagePaymentModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);
  const [usages, setUsages] = useState<Usage[]>([]);

  const {
    control: usageControl,
    handleSubmit: usageHandleSubmit,
    reset: usageReset,
  } = useForm<Usage>({
    resolver: yupResolver(usageSchema),
    defaultValues: {
      tickets: 1,
      rate: 1,
    },
  });

  const { control, handleSubmit, reset } = useForm<UsagePayment>({
    resolver: yupResolver(usagePaymentDatesSchema),
    defaultValues: {
      due_date: new Date(),
      date_paid: new Date(),
      notes: "",
    },
  });
  const addUpdateUsagePayment = useAddUpdateUsagePayment(
    client.id as string,
    !!usagePayment,
  );

  const handleClose = () => {
    reset();
    setUsages([]);
    setActive(false);
    setLoading(false);
  };

  const onHandleUsageSubmit = (usage: Usage) => {
    setUsages([...usages, usage]);

    usageReset({
      tickets: 1,
      rate: 1,
    });
  };

  const onHandleSubmit = async (_usagePayment: UsagePayment) => {
    setLoading(true);

    if (!usages.length) {
      onShowToast("Please add at least one usage", true);

      return setLoading(false);
    }

    const oldUsagePayment = usagePayment || {};
    const newUsagePayment: UsagePayment = {
      ...oldUsagePayment,
      ..._usagePayment,
      client_id: client.id,
      usages: [...usages],
      total: 0,
    };

    try {
      await addUpdateUsagePayment(newUsagePayment);

      await queryClient.invalidateQueries([
        queryKeysConstants.usagePayments,
        client.id,
      ]);
      await queryClient.invalidateQueries([
        queryKeysConstants.client,
        client.id,
      ]);

      setUsages([]);
      handleClose();

      onShowToast("Usage Payment Added", false);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    return setLoading(false);
  };

  useEffect(() => {
    reset({
      due_date: usagePayment?.due_date || new Date(),
      date_paid: usagePayment?.date_paid || new Date(),
      notes: usagePayment?.notes || "",
    });

    setUsages(usagePayment ? [...usagePayment.usages] : []);
  }, [usagePayment]);

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={`${usagePayment ? "Update" : "Add"} Usage Payment`}
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <InlineStack gap="100" blockAlign="end">
          <FormLayout>
            <FormLayout.Group condensed>
              <TextFieldInput
                control={usageControl}
                label="# of Tickets"
                name="tickets"
                type="number"
              />
              <TextFieldInput
                control={usageControl}
                label="Rate"
                name="rate"
                type="number"
              />
            </FormLayout.Group>
          </FormLayout>
          <Button
            variant="primary"
            onClick={usageHandleSubmit(onHandleUsageSubmit)}
          >
            Add
          </Button>
        </InlineStack>
      </Modal.Section>
      <Modal.Section flush>
        <DataTable
          columnContentTypes={["numeric", "numeric", "numeric"]}
          headings={["Rate", "# of Tickets", "Total"]}
          hasZebraStripingOnData
          rows={usages.map((usage: Usage) => [
            formatMoney(usage.rate),
            usage.tickets,
            formatMoney(usage.tickets * usage.rate),
          ])}
          showTotalsInFooter
          totals={[
            "",
            sumArray(usages.map((usage) => usage.tickets)),
            formatMoney(
              sumArray(usages.map((usage) => usage.tickets * usage.rate)),
            ),
          ]}
          totalsName={{
            singular: "Totals",
            plural: "Totals",
          }}
        />
      </Modal.Section>
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <DatePickerInput
              control={control}
              label="Due Date"
              name="due_date"
              initialDate={
                usagePayment?.due_date || client.next_billing_at || new Date()
              }
            />
            <DatePickerInput
              control={control}
              label="Date Paid"
              name="date_paid"
              initialDate={usagePayment?.date_paid || new Date()}
            />
          </FormLayout.Group>
          <TextFieldInput
            control={control}
            name="notes"
            label="Notes"
            multiline={4}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
