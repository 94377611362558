import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Agent } from "../../../../types/common.types";

async function updateAgent(agentId: string, agent: Agent): Promise<Agent> {
  return axiosInstance.put(`/agents/${agentId}`, agent, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useUpdateAgent(
  agentId: string,
): UseMutateAsyncFunction<Agent, unknown, Agent, unknown> {
  const { mutateAsync } = useMutation((agent: Agent) =>
    updateAgent(agentId, agent),
  );

  return mutateAsync;
}
