import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import {
  Modal,
  FormLayout,
  Button,
  IndexTable,
  InlineStack,
} from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldInput from "../../../../../components/TextFieldInput";
import SelectInput from "../../../../../components/SelectInput";
import { formatMoney } from "../../../../../helpers/helpers";
import DatePickerInput from "../../../../../components/DatePickerInput";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import { useProducts } from "../../../../../hooks/useProducts";
import { Product } from "../../../../../types/products.types";
import { Payment, PaymentProduct } from "../../../../../types/payments.types";
import { useAddUpdatePayment } from "./hooks/useAddUpdatePayment";

interface PaymentModalProps {
  clientId: string;
  payment?: Payment | null;
  active: boolean;
  setActive: (status: boolean) => void;
}

const productSchema = yup.object({
  product_id: yup.string().required(),
  price: yup.number().nullable(),
  quantity: yup.number().min(1).required(),
});
export default function PaymentModal({
  clientId,
  payment,
  active,
  setActive,
}: PaymentModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);
  const [paymentProducts, setPaymentProducts] = useState<PaymentProduct[]>([]);

  const { products, isLoading: isProductsLoading } = useProducts();

  const {
    control: productControl,
    handleSubmit: productHandleSubmit,
    setValue: setProductValue,
    reset: productReset,
  } = useForm<PaymentProduct>({
    resolver: yupResolver(productSchema),
    defaultValues: {
      price: 0,
      quantity: 1,
    },
  });

  const { control, handleSubmit, reset } = useForm<Payment>({
    resolver: yupResolver(
      yup.object({
        date_paid: yup.date().required(),
        notes: yup.string().nullable(),
      }),
    ),
    defaultValues: {
      date_paid: payment?.date_paid || new Date(),
      notes: "",
    },
  });
  const addUpdatePayment = useAddUpdatePayment(clientId, !!payment);

  const getProductById = (productId: string): Product | undefined =>
    products.find((product: Product) => {
      return product.id?.match(productId);
    });

  const handleClose = () => {
    // reset();
    setActive(false);
    setLoading(false);
  };

  const onHandlePaymentProductSubmit = (paymentProduct: PaymentProduct) => {
    setPaymentProducts([
      ...paymentProducts,
      {
        ...paymentProduct,
        price: getProductById(paymentProduct.product_id)?.price || 0,
      },
    ]);

    productReset({
      product_id: products[0].id || "",
      price: products[0].price || 0,
      quantity: 1,
    });
  };

  const onHandleSubmit = async (_payment: Payment) => {
    setLoading(true);

    if (!paymentProducts.length) {
      onShowToast("Please add at least one usage", true);

      return setLoading(false);
    }

    const oldPayment = payment || {};

    try {
      await addUpdatePayment({
        ...oldPayment,
        ..._payment,
        client_id: clientId,
        products: [...paymentProducts],
        total: 0,
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.payments,
        clientId,
      ]);
      await queryClient.invalidateQueries([
        queryKeysConstants.client,
        clientId,
      ]);

      setPaymentProducts([]);
      handleClose();

      onShowToast("Payment Added", false);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    return setLoading(false);
  };

  useEffect(() => {
    if (!products.length) return;

    setProductValue("product_id", products[0].id || "");
    setProductValue("price", products[0].price || 0);
  }, [products]);

  useEffect(() => {
    reset({
      date_paid: payment?.date_paid || new Date(),
      notes: payment?.notes || "",
    });

    setPaymentProducts(payment?.products || []);
  }, [payment]);

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={`${payment ? "Edit" : "Add"} Payment`}
      loading={isProductsLoading}
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <InlineStack
          gap="100"
          wrap={false}
          align="space-between"
          blockAlign="end"
        >
          <FormLayout>
            <FormLayout.Group>
              <SelectInput
                control={productControl}
                name="product_id"
                label="Product"
                options={products.map((product: Product) => ({
                  label: `${product.name} - ${formatMoney(product.price)}`,
                  value: product.id,
                }))}
              />
              <TextFieldInput
                control={productControl}
                label="Quantity"
                name="quantity"
                type="number"
              />
            </FormLayout.Group>
          </FormLayout>
          <Button
            variant="primary"
            onClick={productHandleSubmit(onHandlePaymentProductSubmit)}
          >
            Add
          </Button>
        </InlineStack>
      </Modal.Section>
      <Modal.Section flush>
        <IndexTable
          resourceName={{
            singular: "order",
            plural: "orders",
          }}
          itemCount={paymentProducts.length}
          headings={[
            { title: "Product" },
            { title: "Quantity" },
            { title: "Price" },
            { title: "Total" },
          ]}
          selectable={false}
        >
          {paymentProducts.map((paymentProduct: PaymentProduct, index) => (
            <IndexTable.Row
              id={`${index}`}
              key={`key-${index + 1}`}
              position={index}
            >
              <IndexTable.Cell>
                {getProductById(paymentProduct.product_id)?.name}
              </IndexTable.Cell>
              <IndexTable.Cell>{paymentProduct.quantity}</IndexTable.Cell>
              <IndexTable.Cell>
                {formatMoney(paymentProduct.price)}
              </IndexTable.Cell>
              <IndexTable.Cell>
                {formatMoney(paymentProduct.price * paymentProduct.quantity)}
              </IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
      </Modal.Section>
      <Modal.Section>
        <FormLayout>
          <DatePickerInput
            control={control}
            label="Date Paid"
            name="date_paid"
          />
          <TextFieldInput
            control={control}
            name="notes"
            label="Notes"
            multiline={4}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
