import { useQuery } from "@tanstack/react-query";
import { Agent } from "../../../../types/common.types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getAgent(agentId: string): Promise<Agent> {
  const { data } = await axiosInstance.get(`/agents/${agentId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface Props {
  isLoading: boolean;
  isError: boolean;
  agent: Agent | undefined;
}

export function useAgent(agentId: string): Props {
  const { data, isLoading, isError } = useQuery(
    [queryKeysConstants.agents, agentId],
    () => getAgent(agentId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isError,
    isLoading,
    agent: data,
  };
}
