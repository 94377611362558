import { useMutation } from "@tanstack/react-query";
import { UseMutateAsyncFunction } from "@tanstack/react-query/src/types";
import { axiosInstance } from "../services/axios.service";
import { getAccessToken } from "../services/auth.service";

async function downloadReceipts(
  clientId: string,
  type: string,
  ids: string[],
): Promise<any> {
  const { data } = await axiosInstance.post(
    `/clients/${clientId}/${type}/receipts`,
    {
      ids,
    },
    {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );

  return data;
}

export function useDownloadReceipts(
  clientId: string,
  type: string,
): UseMutateAsyncFunction<any, unknown, string[]> {
  const { mutateAsync } = useMutation((ids: string[]) =>
    downloadReceipts(clientId, type, ids),
  );

  return mutateAsync;
}
