import { useMutation } from "@tanstack/react-query";
import { UseMutateAsyncFunction } from "@tanstack/react-query/src/types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Client } from "../../../../types/clients.types";

async function addClient(client: Client): Promise<Client> {
  const { data } = await axiosInstance.post(`/clients/`, client, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

export function useAddClient(): UseMutateAsyncFunction<
  Client,
  unknown,
  Client
> {
  const { mutateAsync } = useMutation((client: Client) => addClient(client));

  return mutateAsync;
}
