import { Text } from "@shopify/polaris";
import React from "react";

export function GridField({ name, children }: { name: string; children: any }) {
  return (
    <p>
      <Text as="span" fontWeight="bold">
        {name}
      </Text>
      <br />
      <Text variant="bodyMd" as="span" tone="subdued">
        {children}
      </Text>
    </p>
  );
}
