import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { Client } from "../../../../types/clients.types";
import { getAccessToken } from "../../../../services/auth.service";

async function getClient(clientId: string): Promise<Client> {
  const { data } = await axiosInstance.get(`/clients/${clientId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

interface UseClient {
  isLoading: boolean;
  isError: boolean;
  client: Client | null;
}

export function useClient(clientId: string): UseClient {
  const {
    data: client = null,
    isLoading,
    isError,
  } = useQuery(
    [queryKeysConstants.client, clientId],
    () => getClient(clientId),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isError,
    client,
  };
}
