import {
  Layout,
  Page,
  FormLayout,
  FooterHelp,
  Link,
  Text,
  Card,
  BlockStack,
  Button,
} from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "../../../types/common.types";
import { logInWithEmailAndPassword } from "../../../services/auth.service";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import TextFieldInput from "../../../components/TextFieldInput";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

export default function Login() {
  const query = useQuery();
  const navigate = useNavigate();
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<User>({
    resolver: yupResolver(schema),
  });

  const onHandleSubmit = async (user: User) => {
    setLoading(true);

    try {
      await logInWithEmailAndPassword(user);

      await queryClient.invalidateQueries([queryKeysConstants.user]);

      navigate(query.get("return_to") || "/", {
        replace: true,
      });
    } catch (err) {
      onShowToast("Invalid Credentials", true);
    }

    setLoading(false);
  };

  return (
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap="500">
              <Text as="span" fontWeight="bold">
                Login
              </Text>
              <FormLayout>
                <TextFieldInput control={control} name="email" label="Email" />
                <TextFieldInput
                  control={control}
                  name="password"
                  label="Password"
                  type="password"
                />
              </FormLayout>
              <Button
                variant="primary"
                size="large"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                Login
              </Button>
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
      <FooterHelp>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link url="/auth/forgot-password">Forgot Password?</Link>
      </FooterHelp>
    </Page>
  );
}
