import React, { useState } from "react";
import { TopBar } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { logOut } from "../services/auth.service";
import { useUser } from "../pages/Auth/Login/hooks/useUser";
import { queryClient } from "../services/queryClient.service";
import queryKeysConstants from "../constants/queryKeys.constants";

interface Props {
  onNavigationToggle?(): void;
}

export default function Header({ onNavigationToggle }: Props) {
  const navigate = useNavigate();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { user, isError } = useUser();

  const onLogOut = async () => {
    await logOut();
    await queryClient.invalidateQueries([queryKeysConstants.user]);

    navigate("/auth/login", {
      replace: true,
    });
  };

  const userMenuMarkup =
    user && !isError ? (
      <TopBar.UserMenu
        actions={[
          {
            items: [
              {
                content: "Log Out",
                onAction: onLogOut,
              },
            ],
          },
        ]}
        name={user.name}
        initials="D"
        open={isUserMenuOpen}
        onToggle={() => setIsUserMenuOpen(!isUserMenuOpen)}
      />
    ) : null;

  return (
    <TopBar
      showNavigationToggle={!!user}
      userMenu={userMenuMarkup}
      onNavigationToggle={onNavigationToggle}
    />
  );
}
