import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";
import { UsagePayment } from "../../../../../../types/usagePayments.types";

async function addUsagePayment(
  clientId: string,
  usagePayment: UsagePayment,
): Promise<void> {
  await axiosInstance.post(
    `/clients/${clientId}/usage_payments/`,
    usagePayment,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

async function updateUsagePayment(
  clientId: string,
  usagePayment: UsagePayment,
): Promise<void> {
  await axiosInstance.put(
    `/clients/${clientId}/usage_payments/${usagePayment.id}`,
    usagePayment,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useAddUpdateUsagePayment(
  clientId: string,
  update: boolean,
): UseMutateFunction<void, unknown, UsagePayment, unknown> {
  const { mutateAsync } = useMutation((usagePayment: UsagePayment) =>
    update
      ? updateUsagePayment(clientId, usagePayment)
      : addUsagePayment(clientId, usagePayment),
  );

  return mutateAsync;
}
