import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { UsagePayment } from "../../../../../../types/usagePayments.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";
import { Page } from "../../../../../../types/common.types";

async function getClientUsagePayments(
  clientId: string,
  page = 1,
  size = 10,
): Promise<Page<UsagePayment>> {
  const { data } = await axiosInstance.get(
    `/clients/${clientId}/usage_payments/`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      params: {
        page,
        size,
      },
    },
  );

  return data;
}

interface Props {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  usagePayments: UsagePayment[];
}

export function useClientUsagePayments(clientId: string, size = 10): Props {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.usagePayments, clientId, page, size],
    () => getClientUsagePayments(clientId, page, size),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    usagePayments: data?.items || [],
  };
}
