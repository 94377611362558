import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteAgentPayments(
  agentId: string,
  ids: string[],
): Promise<void> {
  await axiosInstance.delete(
    `/agents/${agentId}/payments/?ids=${ids.join(",")}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useDeleteAgentPayments(
  agentId: string,
): UseMutateAsyncFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((ids: string[]) =>
    deleteAgentPayments(agentId, ids),
  );

  return mutateAsync;
}
