import { Controller } from "react-hook-form";
import {
  DatePicker,
  Popover,
  Box,
  Icon,
  TextField,
  BlockStack,
  LegacyCard,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { CalendarIcon } from "@shopify/polaris-icons";
import moment from "moment";

interface Props {
  control: any;
  name: string;
  label: string;
  initialDate?: Date | string;
}

export default function DatePickerInput({
  control,
  name,
  label,
  initialDate = new Date(),
}: Props) {
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line no-underscore-dangle
  const initialValue =
    typeof initialDate === "string" ? new Date(initialDate) : initialDate;

  const [selectedDate, setSelectedDate] = useState(initialValue);
  const [{ month, year }, setDate] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
  });

  const dateToUtc = (date: Date) =>
    new Date(moment(date).utcOffset(0, true).format());

  const formattedValue = selectedDate.toString().slice(0, 10);

  const handleOnClose = () => {
    setVisible(false);
  };

  const handleMonthChange = (month: any, year: any) => {
    setDate({ month, year });
  };

  const handleDateSelection = (end: Date) => {
    setSelectedDate(end);
    setVisible(false);
  };

  useEffect(() => {
    if (selectedDate) {
      setDate({
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      });
    }
  }, [selectedDate]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, formState: { errors } }) => (
        <BlockStack>
          <Box minWidth="276px" width="100%">
            <Popover
              active={visible}
              autofocusTarget="none"
              fullWidth
              preferInputActivator={false}
              preferredPosition="below"
              onClose={handleOnClose}
              activator={
                <TextField
                  role="combobox"
                  label={label}
                  name={name}
                  prefix={<Icon source={CalendarIcon} />}
                  value={formattedValue}
                  onFocus={() => setVisible(true)}
                  autoComplete="off"
                  error={errors[name]?.message as any}
                />
              }
            >
              <LegacyCard>
                <DatePicker
                  month={month}
                  year={year}
                  selected={selectedDate}
                  onMonthChange={handleMonthChange}
                  onChange={({ end }) => {
                    handleDateSelection(dateToUtc(end));
                    onChange(dateToUtc(end));
                  }}
                />
              </LegacyCard>
            </Popover>
          </Box>
        </BlockStack>
      )}
    />
  );
}
