import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Page } from "../../../../types/common.types";
import { Client } from "../../../../types/clients.types";

async function getClients(
  page = 1,
  size = 10,
  status = "",
  query = "",
): Promise<Page<Client>> {
  const { data } = await axiosInstance.get(
    `/clients/?page=${page}&size=${size}&status=${status}&query=${query}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
  return data;
}

interface UseClients {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  clients: Client[];
}

export function useClients(
  size = 10,
  status = "active",
  query = "",
): UseClients {
  const [page, setPage] = useState(1);

  const { data, isFetching } = useQuery(
    [queryKeysConstants.clients, page, status, query],
    () => getClients(page, size, status, query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    page,
    setPage,
    clients: data?.items || [],
  };
}
