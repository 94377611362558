import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteUsagePayments(
  agentId: string,
  billsIds: string[],
): Promise<void> {
  await axiosInstance.delete(
    `/clients/${agentId}/usage_payments/?ids=${billsIds.join(",")}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useDeleteUsagePayments(
  clientId: string,
): UseMutateFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((billsIds: string[]) =>
    deleteUsagePayments(clientId, billsIds),
  );

  return mutateAsync;
}
