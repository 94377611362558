import {
  IndexTable,
  useIndexResourceState,
  Card,
  BlockStack,
  Box,
  InlineStack,
  Button,
  Text,
} from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { SelectionType } from "@shopify/polaris/build/ts/src/utilities/index-provider";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import {
  downloadFile,
  formatDate,
  formatMoney,
} from "../../../../../helpers/helpers";
import { useClientUsagePayments } from "./hooks/useClientUsagePayments";
import { UsagePayment } from "../../../../../types/usagePayments.types";
import UsagePaymentModal from "./UsagePaymentModal";
import { useDeleteUsagePayments } from "./hooks/useDeleteUsagePayments";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import { useDownloadReceipts } from "../../../../../hooks/useDownloadReceipts";
import { Client } from "../../../../../types/clients.types";

export function UsagePaymentsTable({ client }: { client: Client }) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [modalUsagePayment, setModalUsagePayment] =
    useState<UsagePayment | null>(null);
  const [usagePaymentModalActive, setUsagePaymentModalActive] = useState(false);

  const { usagePayments, isFetching, page, maxPage, minPage, setPage } =
    useClientUsagePayments(client.id as string);
  const deleteUsagePayments = useDeleteUsagePayments(client.id as string);
  const downloadReceipts = useDownloadReceipts(
    client.id as string,
    "usage_payments",
  );

  const resourceName = {
    singular: "Usage Payment",
    plural: "Usage Payments",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(
      usagePayments.map((usagePayment: UsagePayment) => ({
        ...usagePayment,
      })),
    );

  const onHandleDeleteUsagePayments = async () => {
    onShowToast("Deleting Usage Payments", false);

    try {
      await deleteUsagePayments(selectedResources);
      await queryClient.invalidateQueries([
        queryKeysConstants.usagePayments,
        client.id,
      ]);
      await queryClient.invalidateQueries([
        queryKeysConstants.client,
        client.id,
      ]);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    handleSelectionChange("all" as SelectionType, false);
  };

  const onHandleDownloadReceipts = async () => {
    onShowToast("Downloading Invoices", false);
    handleSelectionChange("all" as SelectionType, false);

    try {
      const blob = await downloadReceipts(selectedResources);

      downloadFile(blob, "usage-payments-receipts.zip");
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }
  };

  const promotedBulkActions = [
    {
      content: "Delete Usage Payments",
      onAction: onHandleDeleteUsagePayments,
    },
    {
      content: "Download Receipts",
      onAction: onHandleDownloadReceipts,
    },
  ];

  const handleUsagePaymentModalActive = (val: boolean) => {
    setUsagePaymentModalActive(val);

    if (!val) {
      setModalUsagePayment(null);
    }
  };

  const rowMarkup = usagePayments.map((usagePayment, index) => (
    <IndexTable.Row
      id={usagePayment.id as string}
      key={usagePayment.id}
      selected={selectedResources.includes(usagePayment.id as string)}
      position={index}
      onClick={() => {
        setModalUsagePayment(usagePayment);
        setUsagePaymentModalActive(true);
      }}
    >
      <IndexTable.Cell>{formatDate(usagePayment.due_date)}</IndexTable.Cell>
      <IndexTable.Cell>{formatDate(usagePayment.date_paid)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(usagePayment.total)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              Usage Payments
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                setModalUsagePayment(null);
                setUsagePaymentModalActive(true);
              }}
            >
              Add
            </Button>
          </InlineStack>
        </Box>
        <IndexTable
          resourceName={resourceName}
          itemCount={usagePayments.length}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          promotedBulkActions={promotedBulkActions}
          headings={[
            { title: "Due Date" },
            { title: "Date paid" },
            { title: "Total" },
          ]}
          loading={isFetching}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
        <UsagePaymentModal
          usagePayment={modalUsagePayment}
          client={client as Client}
          active={usagePaymentModalActive}
          setActive={handleUsagePaymentModalActive}
        />
      </BlockStack>
    </Card>
  );
}
