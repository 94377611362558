import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const app = initializeApp({
  apiKey: "AIzaSyC98HhzJ6BupiYd-ojzxtWTM7BLyboF2-Q",
  authDomain: "cs-backend-627a6.firebaseapp.com",
  projectId: "cs-backend-627a6",
  storageBucket: "cs-backend-627a6.appspot.com",
  messagingSenderId: "77418020258",
  appId: "1:77418020258:web:d0947537ac1869efd986fb",
  measurementId: "G-WBEV70QGYE",
});
export const auth = getAuth(app);
