import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Client } from "../../../../types/clients.types";

async function updateClient(client: Client): Promise<void> {
  await axiosInstance.put(`/clients/${client.id}`, client, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useUpdateClient(): UseMutateAsyncFunction<
  void,
  unknown,
  Client,
  unknown
> {
  const { mutateAsync } = useMutation((client: Client) => updateClient(client));

  return mutateAsync;
}
