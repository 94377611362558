import {
  Page,
  IndexTable,
  Link,
  Badge,
  Text,
  IndexFilters,
  useSetIndexFiltersMode,
  TabProps,
  Card,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useClients } from "./hooks/useClients";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import AddClientModal from "./AddClientModal";

export default function ClientsPage() {
  const navigate = useNavigate();
  const { mode, setMode } = useSetIndexFiltersMode();

  const [selected, setSelected] = useState(0);
  const [clientsStatus, setClientsStatus] = useState("pending-payment");
  const [queryValue, setQueryValue] = useState("");

  const [addClientModalActive, setAddClientModalActive] = useState(false);

  const { minPage, maxPage, isFetching, page, setPage, clients } = useClients(
    10,
    clientsStatus,
    queryValue,
  );
  const columns = [
    "Client name",
    "Company name",
    "Status",
    "Payment schedule",
    "Monthly rate",
    "Stores",
    "Started at",
    "Last billing at",
    "Next billing at",
  ];

  const tabs: TabProps[] = [
    {
      id: "pending-payment",
      content: "Pending payment",
    },
    {
      id: "active",
      content: "Active",
    },
    {
      id: "inactive",
      content: "Inactive",
    },
    {
      id: "all",
      content: "All",
    },
  ];

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setClientsStatus(tabs[value].id);
  }, []);

  const rowMarkup = clients.map(
    (
      {
        id,
        active,
        name,
        company_name: companyName,
        payment_schedule: paymentSchedule,
        monthly_rate: monthlyRate,
        total_stores: totalStores,
        total_paid: totalPaid,
        started_at: startedAt,
        last_billed_at: lastBillingAt,
        next_billing_at: nextBillingAt,
      },
      index,
    ) => (
      <IndexTable.Row id={id as string} key={id} position={index}>
        <IndexTable.Cell>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            monochrome
            dataPrimaryLink
            onClick={() => navigate(`/admin/clients/${id}`)}
          >
            <Text as="span" fontWeight="bold">
              {name}
            </Text>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{companyName}</IndexTable.Cell>
        <IndexTable.Cell>
          <Badge tone={active ? "success" : "critical"}>
            {active ? "Active" : "Not active"}
          </Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {paymentSchedule === "monthly" ? "Monthly" : "BI Weekly"}
        </IndexTable.Cell>
        <IndexTable.Cell>{formatMoney(monthlyRate)}</IndexTable.Cell>
        <IndexTable.Cell>{totalStores}</IndexTable.Cell>
        <IndexTable.Cell>{formatDate(startedAt, true, true)}</IndexTable.Cell>
        <IndexTable.Cell>
          {lastBillingAt ? formatDate(lastBillingAt, true, true) : "-"}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {nextBillingAt ? formatDate(nextBillingAt, true, true) : "-"}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Page
      fullWidth
      title="Clients"
      primaryAction={{
        content: "Add",
        onAction() {
          setAddClientModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          queryValue={queryValue}
          queryPlaceholder="Searching"
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => {}}
          cancelAction={{
            onAction: () => {},
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          filters={[]}
          onClearAll={() => {}}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: "Client",
            plural: "Clients",
          }}
          itemCount={clients.length}
          loading={isFetching}
          headings={
            columns.map(
              (column: string): IndexTableHeading => ({ title: column }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
        <AddClientModal
          active={addClientModalActive}
          setActive={setAddClientModalActive}
        />
      </Card>
    </Page>
  );
}
