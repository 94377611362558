import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { Agent } from "../../../types/common.types";
import { useAddAgent } from "./hooks/useAddAgent";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import TextFieldInput from "../../../components/TextFieldInput";
import SelectInput from "../../../components/SelectInput";
import { AgentTypes } from "../../../constants/common.constants";
import { capitalize } from "../../../helpers/helpers";

interface CarModalProps {
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().required(),
    salary: yup.number().required(),
    type: yup.string().required(),
  })
  .required();

export default function NewAgentModal({ active, setActive }: CarModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<Agent>({
    defaultValues: {
      salary: 0,
      type: AgentTypes[0],
    },
    resolver: yupResolver(schema),
  });

  const addAgent = useAddAgent();

  const handleClose = () => {
    reset({
      first_name: "",
      last_name: "",
      email: "",
      salary: 0,
      type: AgentTypes[0],
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (agent: Agent) => {
    setLoading(true);

    try {
      await addAgent(agent);

      await queryClient.invalidateQueries([queryKeysConstants.agents]);

      handleClose();

      onShowToast("Agent Saved", false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title="New Agent"
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput
            control={control}
            label="First Name"
            name="first_name"
            type="text"
          />
          <TextFieldInput
            control={control}
            label="Last Name"
            name="last_name"
            type="text"
          />
          <TextFieldInput
            control={control}
            label="Email"
            name="email"
            type="text"
          />
          <TextFieldInput
            control={control}
            label="Salary"
            name="salary"
            type="number"
          />
          <SelectInput
            control={control}
            name="type"
            label="Type"
            options={AgentTypes.map((agentType) => ({
              label: capitalize(agentType.toLowerCase()),
              value: agentType,
            }))}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
