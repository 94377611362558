import {
  Card,
  BlockStack,
  Text,
  InlineStack,
  Button,
  FormLayout,
  InlineGrid,
} from "@shopify/polaris";
import { useForm } from "react-hook-form";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Agent } from "../../../../types/common.types";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import { useUpdateAgent } from "../hooks/useUpdateAgent";
import { capitalize, formatMoney } from "../../../../helpers/helpers";
import TextFieldInput from "../../../../components/TextFieldInput";
import SelectInput from "../../../../components/SelectInput";
import { GridField } from "../../../../components/GridField";

interface Props {
  agent: Agent;
}

const schema = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().required(),
    salary: yup.string().required(),
    type: yup.string().required(),
  })
  .required();

export default function AgentPersonalDetails({ agent }: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm<Agent>({
    defaultValues: {
      first_name: agent.first_name,
      last_name: agent.last_name,
      email: agent.email,
      salary: agent.salary,
      type: agent.type,
    },
    resolver: yupResolver(schema),
  });

  const updateAgent = useUpdateAgent(agent.id as string);

  const onHandleSubmit = async (updatedAgent: Agent) => {
    setLoading(true);

    try {
      await updateAgent(updatedAgent);

      await queryClient.invalidateQueries([
        queryKeysConstants.agents,
        agent.id,
      ]);

      onShowToast("Agent Updated", false);

      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            Personal Details
          </Text>
          <Button
            variant="plain"
            onClick={() => setEdit((prevState: boolean) => !prevState)}
            loading={loading}
          >
            {edit ? "Cancel" : "Edit"}
          </Button>
        </InlineStack>
        {!edit && (
          <InlineGrid columns={{ sm: 2, lg: 2 }} gap="200">
            <GridField name="First Name">{agent?.first_name}</GridField>
            <GridField name="Last Name">{agent?.last_name}</GridField>
            <GridField name="Email">{agent?.email}</GridField>
            <GridField name="Salary">
              {formatMoney(agent?.salary || 0)}
            </GridField>
            <GridField name="Type">
              {capitalize(agent?.type.toLowerCase())}
            </GridField>
          </InlineGrid>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <FormLayout.Group>
                <TextFieldInput control={control} name="name" label="Name" />
                <TextFieldInput control={control} name="email" label="Email" />
                <TextFieldInput
                  control={control}
                  name="salary"
                  label="Salary"
                  type="number"
                />
                <SelectInput
                  control={control}
                  name="type"
                  label="Type"
                  options={[
                    {
                      label: "Owner",
                      value: "OWNER",
                    },
                    {
                      label: "Agent",
                      value: "AGENT",
                    },
                  ]}
                />
              </FormLayout.Group>
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                Save
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}
