import { useQuery } from "@tanstack/react-query";
import { Product } from "../types/products.types";
import { axiosInstance } from "../services/axios.service";
import { getAccessToken } from "../services/auth.service";
import queryKeysConstants from "../constants/queryKeys.constants";

async function getProducts(): Promise<Product[]> {
  const { data } = await axiosInstance.get(`/products/`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface UseProducts {
  isLoading: boolean;
  isFetching: boolean;
  products: Product[];
}

export function useProducts(): UseProducts {
  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.products],
    () => getProducts(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isFetching,
    products: data || [],
  };
}
