import { FormLayout, Modal } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Client, PaymentSchedule } from "../../../types/clients.types";
import TextFieldInput from "../../../components/TextFieldInput";
import SelectInput from "../../../components/SelectInput";
import DatePickerInput from "../../../components/DatePickerInput";
import { useAddClient } from "./hooks/useAddClient";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";

interface Props {
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().required(),
    company_name: yup.string().required(),
    payment_schedule: yup.string().required(),
    started_at: yup.date().required(),
    zendesk_name: yup.string().required(),
    zendesk_email: yup.string().required(),
    zendesk_password: yup.string().required(),
  })
  .required();

export default function AddClientModal({ active, setActive }: Props) {
  const navigate = useNavigate();
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<Client>({
    defaultValues: {
      payment_schedule: PaymentSchedule.BI_WEEKLY,
      started_at: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const addClient = useAddClient();

  const handleClose = () => {
    reset();
    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (client: Client) => {
    setLoading(true);

    try {
      const newClient = await addClient(client);

      onShowToast("Client Added", false);

      navigate(`/admin/clients/${newClient.id}`);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title="Add Client"
      primaryAction={{
        content: "Save",
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput control={control} name="name" label="Name" />
          <TextFieldInput control={control} name="email" label="Email" />
          <TextFieldInput
            control={control}
            name="company_name"
            label="Company Name"
          />
          <DatePickerInput
            control={control}
            name="started_at"
            label="Started At"
          />
          <SelectInput
            control={control}
            name="payment_schedule"
            label="Payment Schedule"
            options={[
              { label: "Bi-Weekly", value: "bi_weekly" },
              { label: "Monthly", value: "monthly" },
            ]}
          />
        </FormLayout>
      </Modal.Section>
      <Modal.Section>
        <FormLayout>
          <TextFieldInput
            control={control}
            label="Zendesk Name"
            name="zendesk_name"
          />
          <TextFieldInput
            control={control}
            label="Zendesk Email"
            name="zendesk_email"
          />
          <TextFieldInput
            control={control}
            label="Zendesk Password"
            name="zendesk_password"
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
