import {
  Card,
  BlockStack,
  Text,
  InlineStack,
  Button,
  FormLayout,
  InlineGrid,
} from "@shopify/polaris";
import { useForm } from "react-hook-form";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Agent } from "../../../../types/common.types";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import { useUpdateAgent } from "../hooks/useUpdateAgent";
import TextFieldInput from "../../../../components/TextFieldInput";
import { GridField } from "../../../../components/GridField";

interface Props {
  agent: Agent;
}

const schema = yup
  .object({
    discord_id: yup.string().required(),
    internal_email: yup.string().required(),
    internal_email_password: yup.string().required(),
  })
  .required();

export default function AgentInternalDetails({ agent }: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm<Agent>({
    defaultValues: {
      discord_id: agent.discord_id,
      internal_email: agent.internal_email,
      internal_email_password: agent.internal_email_password,
    },
    resolver: yupResolver(schema),
  });

  const updateAgent = useUpdateAgent(agent.id as string);

  const onHandleSubmit = async (updatedAgent: Agent) => {
    setLoading(true);

    console.log({
      ...agent,
      discord_id: updatedAgent.discord_id,
      internal_email: updatedAgent.internal_email,
      internal_email_password: updatedAgent.internal_email_password,
    });

    try {
      await updateAgent({
        ...agent,
        discord_id: updatedAgent.discord_id,
        internal_email: updatedAgent.internal_email,
        internal_email_password: updatedAgent.internal_email_password,
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.agents,
        agent.id,
      ]);

      onShowToast("Agent Updated", false);

      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            Internal Details
          </Text>
          <Button
            variant="plain"
            onClick={() => setEdit((prevState: boolean) => !prevState)}
            loading={loading}
          >
            {edit ? "Cancel" : "Edit"}
          </Button>
        </InlineStack>
        {!edit && (
          <InlineGrid columns={{ sm: 2, lg: 2 }} gap="200">
            <GridField name="Discord ID">{agent?.discord_id || "-"}</GridField>
            <GridField name="Discord Username">
              {agent?.discord_username || "-"}
            </GridField>
            <GridField name="Email">{agent?.internal_email || "-"}</GridField>
            <GridField name="Email Password">
              {agent?.internal_email_password || "-"}
            </GridField>
          </InlineGrid>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <TextFieldInput
                control={control}
                name="discord_id"
                label="Discord ID"
              />
              <FormLayout.Group>
                <TextFieldInput
                  control={control}
                  name="internal_email"
                  label="Email"
                />
                <TextFieldInput
                  control={control}
                  name="internal_email_password"
                  label="Email Password"
                />
              </FormLayout.Group>
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                Save
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}
